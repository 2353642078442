<template>
  <div class="auth_layout">
    <div class="row">
      <div class="left-wrapper col-12 col-md-6">
        <div class="left-side-layout">
          <swiper
              :slides-per-view="1"
              :space-between="0"
              :pagination="{ clickable: true }"
          >
            <swiper-slide>
              <div class="left-side-image col-12">
                <img src="@/assets/auth/slides/slide-1.svg" alt="">
              </div>
              <div class="left-side-title col-12">
                <p class="font-size-xxl">{{ $t("auth.slides.slide1.heading") }}</p>
                <p> {{ $t("auth.slides.slide1.body") }} </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="left-side-image col-12">
                <img src="@/assets/auth/slides/slide-2.svg" alt="">
              </div>
              <div class="left-side-title col-12">
                <p class="font-size-xxl">{{ $t("auth.slides.slide2.heading") }}</p>
                <p>
                  {{ $t("auth.slides.slide2.body") }}
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="left-side-image col-12">
                <img src="@/assets/auth/slides/slide-3.svg" alt="">
              </div>
              <div class="left-side-title col-12">
                <p class="font-size-xxl">{{ $t("auth.slides.slide3.heading") }}</p>
                <p>
                  {{ $t("auth.slides.slide3.body") }}
                </p>
              </div>
            </swiper-slide>

          </swiper>
          <div class="app-stores row">
            <div class="google-play col-6" v-if="playStoreLink">
              <a :href="playStoreLink">
                <img src="@/assets/app-stores/google_play.png" alt="">
              </a>

            </div>
            <div class="app-store col-6" v-if="appStoreLink">
              <a :href="appStoreLink">
                <img src="@/assets/app-stores/app_store.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="right-wrapper col-12 col-md-6">
        <div class="right-side-layout">
          <div class="logo">
            <img src="@/assets/auth/logo.png" alt="">
          </div>
          <slot></slot>
          <hr>
          <Errors/>

          <!--    Social Login      -->
          <social-login />

          <div class="terms-condition font-size-sm">
            {{ $t("auth.terms-condition.login") }} <br>
            <router-link to="/pages/en/privacy-policy">{{ $t("auth.terms-condition.head") }}</router-link>
          </div>
          <div class="copyright font-size-sm" v-html="$t('auth.copyright.head', {year: currentYear})"></div>
          <div class="button-language">
            <nav-button-languages-select></nav-button-languages-select>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules
import SwiperCore, {Pagination} from 'swiper/core';
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
// Import css for components
import "swiper/components/pagination/pagination.min.css"
//navigation
import "swiper/components/navigation/navigation.scss";
import NavButtonLanguagesSelect from "../../Layout/partials/Nav/NavButtonLanguagesSelect";
import Errors from "../../../components/Errors";
import SocialLogin from "@/views/Auth/partials/SocialLogin";
// install Swiper modules
SwiperCore.use([Pagination]);

export default {
  name: "AuthLayout",
  components: {
    SocialLogin,
    Errors,
    NavButtonLanguagesSelect,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      playStoreLink: process.env.VUE_APP_PLAY_STORE_LINK,
      appStoreLink: process.env.VUE_APP_APP_STORE_LINK
    }
  }
}

</script>

<style lang="scss">

.auth_layout {
  height: 100%;

  & > .row {
    height: 100%;

    .left-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      background-color: #f2bc32;
      padding: 50px 0;

      .left-side-layout {
        .swiper-container {
          width: 100%;
          max-width: 350px;
          padding-left: 10px;

          .swiper-slide {
            background-color: map-get($colors, "mustard-login");
            padding: 0 15px;
            margin-bottom: 50px;

            .left-side-image {
              width: 100%;

              img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
              }
            }

            .left-side-title {
              p {
                color: map-get($colors, "white");
                text-align: center;
                margin: 0;

                &:first-of-type {
                  padding-top: map-get($spacings, "big");
                }

                &:nth-child(2) {
                  width: 100%;
                  max-width: 80%;
                  margin: 0px auto;
                }
              }
            }
          }

          .swiper-pagination-bullet {
            background: white;
            border-radius: .5rem;
          }

          //:global(.swiper-pagination-bullet) {
          //  background: white;
          //  border-radius: .5rem;
          //}

        }

        .app-stores {
          padding: 35px 0px 0px 0px;
          max-width:300px;
          margin:0 auto;

          & > div {
            display: flex;
            padding: 0 5px;

            img {
              width:100%;
            }

            &.google-play {
              justify-content: flex-end;
            }

            &.app-store {
              justify-content: flex-start;
            }
          }

        }
      }
    }

    .right-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .right-side-layout {
        width: 300px;
        margin: 0 auto;
        padding: 20px 0;

        .logo {
          width: 100%;
          text-align: center;

          img {
            height: 40px;
          }
        }

        form {
          padding-top: 50px;

          .form-control {
            background-color: map-get($colors, "gray");
            border: none;
            padding: 10px;
            height: 45px;
            font-size: 14px;
            border-radius: 6px;
          }

          .submit-btn {
            width: 100%;
            border: none;
            padding: 10px;
            height: 45px;
            font-size: 14px;
            border-radius: 6px;
          }
        }

        .register-link {
          padding: 10px 0;
          text-align: center;
          color: map-get($colors, "secondary");

          a {
            color: map-get($colors, "black");
            text-decoration: underline;
            font-weight: 530;
          }
        }

        .forgot-password {
          text-align: center;

          a {
            color: map-get($colors, "secondary");
            text-decoration: underline;
          }
        }

        .terms-condition {
          padding: 10px 0;
          text-align: center;
          color: map-get($colors, "secondary");

          a {
            color: map-get($colors, "black");
            font-weight: 530;
          }
        }

        .copyright {
          color: map-get($colors, "black");
          padding: 20px 0;
          font-weight: 530;
          text-align: center;
        }

        .button-language {
          width: 130px;
          margin: 0 auto;
        }
      }
    }

  }
}

@include media-breakpoint-down(md) {
  .auth_layout {

    & > .row {
      height: auto;

      .left-wrapper {
        order: 2;
        min-height: auto;
        padding: 0px;
        background-color: map-get($colors, "white");

        .left-side-layout {
          .swiper-container {
            display: none;
          }

          .app-stores {
            padding: 0px;
            background-color: white;
          }
        }
      }

      .right-wrapper {
        .right-side-layout {
          padding-bottom: 0px;

          .copyright {
            color: map-get($colors, "secondary");
          }

          .button-language {
            display: none;
          }
        }
      }

    }
  }
}

</style>
